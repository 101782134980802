
/* Compose box */
.compose-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

/* Shared textarea styles */
.compose-input-container textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: rgb(var(--textbox-bg-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  color: rgb(var(--foreground-rgb));
  resize: none;
  overflow: hidden;
  height: 8em;
}

@media (hover: none) and (pointer: coarse) {
  /* Experiment to prevent auto zoom on mobile.
     Applies to small screens and touch devices */
  .compose-input-container textarea {
    font-size: 16px;
  }
}

.compose-box .preview {
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: rgba(var(--textbox-bg-rgb), 0.5);
}

.compose-box .preview h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: rgba(var(--foreground-rgb), 0.7);
}

.compose-box-buttonrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.compose-box-and-close-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  vertical-align: super
}

.user-suggestions {
  position: absolute;
  top: 100%;  /* Position below the textarea */
  left: 0;
  background-color: rgb(var(--content-bg-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: auto;
  min-width: 150px;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  list-style: none;
}

.user-suggestion {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.user-suggestion:hover,
.user-suggestion.selected {
  background-color: rgba(var(--foreground-rgb), 0.1);
}

.compose-input-container {
  position: relative;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.compose-close {
  background: none;
  border: none;
  color: rgba(var(--foreground-rgb), 0.6);
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  height: 2rem;
}

.compose-close:hover {
  color: rgba(var(--foreground-rgb), 0.8);
  background: rgba(var(--foreground-rgb), 0.1);
}


